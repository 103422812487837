import Vue from 'vue';
Vue.prototype.$parseDate = function(date) {
    if (!date) return null
        const [day, month, year] = date.split('-')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}

Vue.prototype.$parseDateEdit = function(date) {
    if (!date) return null
        const [year, month, day] = date.split('/')
        return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
}
Vue.prototype.$parseDateEdit2 = function(date) {
    if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
}

Vue.prototype.$parseDate2 = function(date) {
    if (!date) return null
        const [day, month, year] = date.split('-')
        return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`
}

Vue.prototype.$parseDateIndex = function(date) {
    if (!date) return null
        let dateTime = date.split(' ');
        const [year, month, day] = dateTime[0].split('-')
        return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`
}

Vue.prototype.$parseDateEdit_ = function(date) {
  if (!date) return null
      let dateTime_ = date.split(' ');
      return dateTime_[0]
}

Vue.prototype.$parseDateIndexWithTZ = function(date) {
  if (!date) return null
      let dateTime = date.split('T');
      const [year, month, day] = dateTime[0].split('-')
      return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`
}

Vue.prototype.$getLocalDate = function(date) {
    if (!date) return null
        const fecha =  date?.split('-');
        let mes = '';
        if(fecha){
          switch(fecha[1]){
            case "01":
              mes = 'enero'
              break
            case "02":
              mes = 'febrero'
              break
            case "03":
              mes = 'marzo'
              break
            case "04":
              mes = 'abril'
              break
            case "05":
              mes = 'mayo'
              break
            case "06":
              mes = 'junio'
              break
            case "07":
              mes = 'julio'
              break
            case "08":
              mes = 'agosto'
              break
            case "09":
              mes = 'septiembre'
              break
            case "10":
              mes = 'octubre'
              break
            case "11":
              mes = 'noviembre'
              break
            case "12":
              mes = 'diciembre'
              break
          };
          const fecha_for = fecha[0] + ' de ' + mes;
          return fecha_for;
      }
}
