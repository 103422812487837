<template>
  <v-app>
    <loading :mostrar="isLoading" />
    <v-app-bar 
      app 
      color="#0B4173" 
      dark
    >
      <div class="d-flex align-center">
        <v-row>
          <v-col class="pa-3 ma-3">
            <v-btn 
              v-if="show"
              class="pa-3 ma-3"
              color="#0B4173" 
              width="15px"
              dark 
              @click.stop="drawer = !drawer"
            > 
              <v-icon>mdi-menu</v-icon> 
            </v-btn>
          </v-col>
          <v-col class="pa-3 mb-3 mt-3 ml-2">
            <v-btn
              href="/"
              plain
              class="pa-3 ma-3"
              outlined
            >
              <v-img
                alt="Correos Logo"
                class="shrink mr-2 pa-3 ma-3"
                contain
                src="../../../src/assets/logos/correosv.png"
                transition="scale-transition"
                width="35%"
                style="margin-left: 5%;"
              />
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <v-spacer />
      <v-btn
        v-if="show"
        text
        @click="menu_2 = !menu_2"
      >
        <v-icon>mdi-account-cog-outline</v-icon>
        <span class="mr-2 ml-2">Cuentas</span>
      </v-btn>
      <v-card
        v-if="menu_2"
        color="#FFF"
        class="top-right-card pa-0 ma-0"
      >
        <v-row>
          <v-col class="d-flex align-right justify-end">
            <v-btn
              color="red"
              width="5px"
              @click="menu_2 = !menu_2"
            >
              <v-icon>mdi-menu-open</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-right justify-center">
            <v-btn
              text
              color="red"
              @click="logout"
            >
              <span class="mr-2">Cerrar sesión</span>
              <v-icon>mdi-account-off</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-right justify-end">
            <v-list color="#3ec8df">
              <v-list-group
                v-for="item in items2"
                :key="item.title"
                v-model="item.active"
                :prepend-icon="item.mod_icon"
                width="100%"
                no-action
                color="info"
              >
                <template #activator>
                  <v-list-item-content>
                    <v-list-item-title class="title_2">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="child in item.permissions"
                  :key="child.name"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-btn
                        :href="child.url"
                        width="80%"
                        class="d-flex justify-start text-capitalize pl-1 ml-1"
                        plain
                        color="#000"
                      >
                        <v-icon>{{ child.icon }}</v-icon>{{ ' ' + child.name }}
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-col>
        </v-row>
      </v-card>
      <v-btn
        v-if="!show"
        href="https://www.correos.gob.sv/"
        target="_blank"
        text
      >
        <span class="mr-2">Correos El Salvador</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <v-sheet 
      height="95%" 
      class="img_class" 
    >
      <v-container class="fill-height">
        <v-row 
          align="center" 
          justify="center"
        >
          <v-main>
            <router-view />
          </v-main>
        </v-row>
      </v-container>

      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        width="35%"
      >
        <v-row>
          <v-col>
            <v-divider />
            <v-card
              class="mx-auto pa-0"
              max-width="w-full"
              max-height="h-full"
              elevation="0"
            >
              <v-toolbar
                color="teal"
                dark
                height="max-content"
              >
                <v-toolbar-title>
                  <v-row>
                    <v-col cols="3">
                      <v-btn 
                        color="#0B4173" 
                        dark 
                        width="auto"
                        @click.stop="drawer = !drawer"
                      > 
                        <v-icon>mdi-menu-open</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="9">
                      <v-row>
                        <v-col cols="6">
                          <h5 style="font-size: large;">
                            Menú
                          </h5>
                          <h5 style="font-size: large;">
                            Especies Postales
                          </h5>
                        </v-col>
                        <v-col cols="6">
                          <v-img
                            alt="Correos Logo"
                            class="shrink mr-2"
                            contain
                            src="../../../src/assets/logos/correosv.png"
                            transition="scale-transition"
                            width="85%"
                            style="margin-left: 0%;"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-toolbar-title>
              </v-toolbar>
  
              <v-list>
                <v-list-group
                  v-for="item in items"
                  :key="item.title"
                  v-model="item.active"
                  :prepend-icon="item.mod_icon"
                  width="w-full"
                  no-action
                  color="info"
                >
                  <template #activator>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
  
                  <v-list-item
                    v-for="child in item.permissions"
                    :key="child.name"
                    width="w-full"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-btn
                          :href="child.url"
                          width="w-full"
                          class="d-flex justify-start text-capitalize pl-1 ml-1"
                          plain
                        >
                          <v-icon>{{ child.icon }}</v-icon>{{ ' ' + child.name }}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3 class="ml-2">
              Usted esta autenticado como:
            </h3>
          </v-col>
        </v-row>
        <v-row style="background-color: teal">
          <v-col class="d-flex align-center justify-center">
            <h3 class="ml-2">
              {{ $getUserSession().first_name }}
            </h3>
            <v-icon>mdi-heart</v-icon>
          </v-col>
        </v-row>
      </v-navigation-drawer>
    </v-sheet>
    <v-footer
      class="d-flex flex-column mb-0 pb-0 v_foot"
      color="#0B4173"
      height="55vh"
    >
      <div class="px-4 py-2 bg-black text-center w-100">
        <p style="color:white;">
          {{ new Date().getFullYear() }} — <strong>Sistema de inventarios de especies postales y productos filatélicos --Derechos Reservados <v-icon color="#FFFFFF">mdi-copyright</v-icon></strong> 
        </p>
      </div>
    </v-footer>
  </v-app>
</template>
  <script>
   import menuService from '../../../services/authServices/menuService';
   import authService from '../../../services/authServices/authService';
   import loading from '../loadingVue.vue';
      export default {
        components: {
          loading,
        },
          props: {
              authenticated: Boolean,
              fn_mostrar: Function,
          },
          data(){
            return {
                drawer: null,
                items: [],   
                show: false,    
                menu_2: false,
     
                color_:false,     
                isLoading:false,  
                items2:[],
            }    
          },
          computed : {
            compAuth: function() {
            return this.$props.authenticated;
          },
          },
  
          watch: {
          },
        
          created(){
            this.getMenu();
          },
          methods: {
            async logout() {
              this.isLoading = true;
              // Clear the token from local storage upon logout
              await authService.logout()
              .then(response => {
                localStorage.removeItem('token');
                this.items=[];
                this.show=false;
                this.menu_2 = false;
                this.fn_mostrar();
                this.isLoading = false;
              })
              .catch(error => {
                this.$toast.error('Se ha presentado un error: '+ error.message);
              })
            },
            async getMenu () {
              const token = localStorage.getItem('token');
              if(token) {
                this.isLoading = true;
                this.show=true;
                await menuService.getMenu()
                .then(response => {
                  let items = response.data.permitted_routes;
                  items.forEach(element => {
                    if(element.title === 'Usuario' ||element.title === 'Roles' || element.title === 'Permisos'){
                      this.items2.push(element)
                    }
                    else {
                      this.items.push(element)
                    }
                  });
                })
                .catch(error=>{
                    if(this.compAuth) {
                      this.$toast.error('Su sesión ha expirado: '+ error.message);
                      localStorage.removeItem('token');
                      this.$router.push('/login')
                    }
                })
              this.isLoading = false;

                }
            },
            
          }
            
      }
  </script>
  