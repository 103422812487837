import Vue from 'vue';
import user from '../services/authServices/authService'
Vue.prototype.$verifyRole = function(role_) {
    return user.hasRole(role_);
}
Vue.prototype.$getUserSession = function(){
    return user.userSession();
}

Vue.prototype.$closeExpiredSession = function() {
    this.$toast.error('Su sesión ha expirado');
    localStorage.removeItem('token');
    if(window.location.pathname !== '/login') {
        this.$router.push('/login')
    }
    // this.$getMenuService();
}